<template>
  <div>
    <div class="mb-4">
      <h2>Latihan-Latihan Operasi Berhitung</h2>
    </div>
    <div>
      <v-card class="pa-4">
        <v-container>
          <v-row class="align-center">
            <v-col
              cols="1"
              md="1"
              sm="2"
            >
              <div class="d-flex">
                <div>
                  <v-img
                    src="../../../assets/images/avatars/1.png"
                    width="70"
                    class="rounded-pill"
                  ></v-img>
                </div>
              </div>
            </v-col>
            <v-col
              cols="9"
              offset-md="0"
              offset-sm="0"
              offset="2"
              md="11"
              sm="10"
            >
              <div class="ml-2 ml-md-0">
                <h3 class="text-truncate">
                  Ridho Mckinnon
                </h3>
                <span>Kelas VI</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="align-center mb-5">
            <v-col
              cols="10"
              offset-md="1"
              offset-sm="2"
            >
              <v-row class="align-center">
                <v-col
                  cols="12"
                  md="3"
                  sm="3"
                >
                  <h4>Status</h4>
                  <v-chip
                    class="v-chip theme--light v-size--small v-chip-light-bg success--text"
                  >
                    Sudah Mengumpul
                  </v-chip>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="4"
                >
                  <h4>Waktu Pengerjaan</h4>
                  <div class="d-flex align-center">
                    <div class="mr-1">
                      <v-img
                        src="../../../assets/icons/calendar.svg"
                        width="18"
                      ></v-img>
                    </div>
                    <small>08:04 - 09:58, 19/01/2021
                    </small>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="3"
                >
                  <h4>Keterangan</h4>
                  <div class="d-flex align-center">
                    <div class="mr-1">
                      <v-img
                        src="../../../assets/icons/color/true.svg"
                        width="18"
                      ></v-img>
                    </div>
                    <small style="color: #5ECD0B">Sudah Dikoreksi</small>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  sm="2"
                >
                  <h4>Point</h4>
                  <div class="d-flex align-center">
                    <small>140</small>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <div class="mb-4">
                <h3>Pilihan Ganda</h3>
                <span>Jawaban pilihan ganda akan terkoreksi otomatis oleh sistem</span>
              </div>
              <div>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="multipleOption in multipleOptions"
                    :key="multipleOption.title"
                    class="mb-2"
                  >
                    <v-expansion-panel-header>
                      <v-container>
                        <v-row class="d-md-flex d-flex align-center text-md-center">
                          <v-col
                            md="1"
                            cols="12"
                          >
                            <span>{{ multipleOption.title }}</span>
                          </v-col>
                          <v-col
                            md="2"
                            cols="12"
                          >
                            <span>Jawaban: <b>{{ multipleOption.answer }}</b></span>
                          </v-col>
                          <v-col
                            md="3"
                            cols="12"
                          >
                            <span>Jawaban yang benar: <b>{{ multipleOption.answerTrue }}</b></span>
                          </v-col>
                          <v-col
                            md="2"
                            cols="12"
                            class="order-last"
                          >
                            <span style="color: #EC008C">Pembahasan</span>
                          </v-col>
                          <v-col
                            md="2"
                            cols="12"
                          >
                            <div>
                              <v-chip
                                class=""
                                :color="getColorChip(multipleOption.isCorrect)"
                              >
                                <v-img
                                  :src="multipleOption.correct.icon"
                                  width="20"
                                  height="20"
                                  class="mr-2"
                                ></v-img> <span>{{ multipleOption.correct.title }}</span>
                              </v-chip>
                            </div>
                          </v-col>
                          <v-col
                            md="2"
                            cols="12"
                          >
                            <div>
                              <span><strong>{{ multipleOption.point }}</strong> Point</span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col>
                            <div>
                              <v-divider></v-divider>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <div>
                              <h4>Soal</h4>
                              <p>-4 + 2 = ....</p>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <div>
                              <h4>Kunci Jawaban</h4>
                            </div>
                            <div>
                              <ol type="A">
                                <li>-6</li>
                                <li>2</li>
                                <li>-2</li>
                                <li>6</li>
                              </ol>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <div>
                              <h4>Jawaban Benar</h4>
                              <ol
                                type="A"
                                start="3"
                              >
                                <li>-2</li>
                              </ol>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                          >
                            <div>
                              <h4>Poin</h4>
                              <span>20</span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-4">
                <h3>Essay</h3>
              </div>
              <div class="mb-4">
                <v-expansion-panels>
                  <v-expansion-panel class="mb-2">
                    <v-expansion-panel-header>
                      <v-container>
                        <v-row class="d-flex align-center text-center">
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <span>Soal No 1</span>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <span>Jawaban: <b>Insang</b></span>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            class="order-last"
                          >
                            <span style="color: #EC008C">Pembahasan</span>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <span><strong>40</strong> Point</span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <div>
                          <v-divider></v-divider>
                        </div>
                        <v-row class="d-flex mt-2">
                          <v-col>
                            <div>
                              <h4>Soal</h4>
                              <p>Dengan apa ikan bernafas?</p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div>
                              <h4>Jawaban:</h4>
                              <p>Insang</p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div>
                              <h4>Jawaban yang benar:</h4>
                              <p align="justify">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus repudiandae aliquam ratione. Fugiat atque doloremque, ab perferendis expedita praesentium fugit beatae non laborum esse laboriosam est harum eius animi voluptate.
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- Fix -->
                <!-- <v-card class="pa-4 elevation-3">
                  <div class="d-flex justify-space-between align-center">
                    <span>Soal No 1</span>
                    <span>Jawaban : A</span>
                    <span>Jawaban yang benar: A</span>
                    <div class="d-flex align-center">
                      <span class="mr-2">Masukkan Nilai:</span>
                      <v-text-field
                        outlined
                        dense
                        placeholder="Nilai"
                        style="margin-top: 25px"
                      ></v-text-field>
                      <span>&nbsp;Point</span>
                    </div>
                    <div>
                    </div>
                  </div>
                </v-card> -->
              </div>
              <h3>Total Nilai Pilihan Ganda dan Essay </h3>
              <h1>140 + 0 = <span class="primary--text">140 Point</span></h1>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import iconTrue from '../../../assets/icons/color/true.svg'
import iconFalse from '../../../assets/icons/color/false.svg'

export default {
  data() {
    return {
      multipleOptions: [
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'A',
          correct: {
            icon: iconTrue,
            title: 'Jawaban Benar',
          },
          isCorrect: 1,
          point: 20,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
        {
          title: 'Soal No 1',
          answer: 'A',
          answerTrue: 'B',
          correct: {
            icon: iconFalse,
            title: 'Jawaban Salah',
          },
          isCorrect: 0,
          point: 0,
        },
      ],
    }
  },
  methods: {
    getColorChip(isCorrect) {
      if (isCorrect === 1) return 'v-chip theme--light v-size--default v-chip-light-bg success--text'

      return 'v-chip theme--light v-size--default v-chip-light-bg error--text'
    },
  },
}
</script>

<style>
ol .answer-list {
  display: inline !important;
}
.answer-list li {
  float: left;
}
</style>
